<mat-toolbar *ngIf="!!actions" class="top-bar"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             [class.settings]="isSettingsSection()"
             [class.di-none]="actions?.buttons && !actions.buttons?.length && !breadcrumbs.length"
>
  <div class="toolbar-container" fxLayout="column" fxLayoutAlign="space-between">
    <div class="actions-container" fxLayoutAlign="space-between center">
      <div class="breadcrumbs" fxLayoutAlign="space-between center">
        <div *ngFor="let crumb of breadcrumbs; let index = index;" class="breadcrumb-item">
          <a [routerLink]="crumb.url" [class.current]="index === (breadcrumbs.length - 1)">
            {{ crumb.key | translate: crumb.args }}
          </a>
          <mat-icon *ngIf="index < (breadcrumbs.length - 1)">chevron_right</mat-icon>
        </div>
      </div>
      <div class="cta" fxLayout="row" #actionButtons>
        <ng-container *ngIf="actions?.menu">
          <button
            mat-stroked-button
            color="darkblue"
            class="no-border"
            [matMenuTriggerFor]="menu"
          >
            <mat-icon aria-hidden="true">{{ actions?.menu?.icon }}</mat-icon>
            {{ (actions?.menu?.label || '') | translate }}
          </button>
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let button of actions?.menu?.buttons">
              <a
                mat-menu-item
                (click)="runAction(button.action)"
              >
                <mat-icon *ngIf="button?.icon" aria-hidden="true">{{ button.icon }}</mat-icon>
                <mat-icon *ngIf="button?.iconSvg" aria-hidden="true" svgIcon="{{ button?.iconSvg }}"></mat-icon>
                {{ button.label | translate }}
              </a>
            </ng-container>
          </mat-menu>
        </ng-container>
  
        <ng-container *ngFor="let button of actions?.buttons">
          <div *ngIf="button?.name === 'filters' && actions.buttons.length > 1" class="filter-btn-separator"></div>
          <div fxLayout="column">
            <button
            *ngIf="!hideAction(button)"
            mat-stroked-button
            [color]="button.color"
            [ngClass]="button?.class || ['action-button']"
            [disabled]="disableAction(button.name)"
            [routerLink]="getButtonRouterLink(button)"
            (click)="runAction(button.action)"
            >
              <mat-icon *ngIf="button?.icon" class="size-m" aria-hidden="true">{{ button.icon }}</mat-icon>
              <span>{{ (button?.label || '') | translate }}</span>
              <div *ngIf="button?.name === 'filters' && actions?.filters && filterCounter > 0" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px" class="filters-counter">
                <div>{{ filterCounter }}</div>
              </div>
              <mat-icon *ngIf="button?.name === 'filters' && expandFilters" class="size-m">expand_less</mat-icon>
              <mat-icon *ngIf="button?.name === 'filters' && !expandFilters" class="size-m">expand_more</mat-icon>
            </button>
            <div *ngIf="button?.name === 'filters' && expandFilters" class="filter-expanded"></div>
          </div>
        </ng-container>
      </div>
    </div>
    <app-toolbar-filters
      [filters]="actions?.filters"
      [storeId]="actions?.storeId"
      [expandFilters]="expandFilters"
      [enableLoginUsername]="enableLoginUsername"
      (doFilters)="doApplyFilters($event)"
      (resetFilters)="doResetFilters($event)"
      (filterCounter)="onFilterCounter($event)"
      [class.d-none]="!actions?.filters?.length"
    >
    </app-toolbar-filters>
  </div>
</mat-toolbar>
