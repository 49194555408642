import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.identityManagerBaseUrl}/api/v1`,
  type: 'confirmation_validations',
})
export class ConfirmationValidation extends JsonApiModel {
  @Attribute()
  token!: string;

  @Attribute()
  email!: string;

  @Attribute({ serializedName: 'expires_at' })
  expiresAt!: string;

  @Attribute({ serializedName: 'company_id' })
  companyId: string | undefined;

  @Attribute({ serializedName: 'member_id' })
  memberId: string | undefined;

  @Attribute({ serializedName: 'reset_password_action' })
  resetPasswordAction!: boolean;
}
