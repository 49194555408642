import { Component, Input } from '@angular/core';
import { Member } from '@models/member.model';
import { UntilDestroy } from '@ngneat/until-destroy';

import { MemberNotificationPollingService } from '@services/member-notification-polling.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MemberNotification } from '@models/member/member-notification.model';
import { map, Observable, skip, take, takeWhile, timer } from 'rxjs';
import { LocalStorageService } from '@services/operations.service';
import { stringArrayEqual } from '@core/helper/array-helper';
import { NotificationCenterService } from '@services/notification-center.service';
import { Roles } from '@core/constants/roles.constant';
import { SnackBarService } from '@services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent {
  @Input() currentMember: Member | undefined;

  timeRemaining$!: Observable<number>;
  dataSource: MatTableDataSource<MemberNotification>;
  columnsToDisplay = ['operation', 'timeStarted', 'status', 'owner', 'actions'];

  currentNotificationIds: string[] = [];
  disabledRefresh = false;

  ROLES = Roles;

  constructor(
    private notificationService: MemberNotificationPollingService,
    public router: Router,
    private storeService: LocalStorageService,
    private notificationCenter: NotificationCenterService,
    private snackbarService: SnackBarService,
    private translateService: TranslateService,
  ) {
    const savedNotificationsIds = this.storeService.getItem(
      'NotificationCenter-NoOfNotifications',
    );
    if (Array.isArray(savedNotificationsIds)) {
      this.currentNotificationIds = savedNotificationsIds;
    }
    this.notificationService.notifications$
      .pipe(skip(1))
      .subscribe(notifications => {
        this.notify(notifications);
      });
    this.dataSource = new MatTableDataSource();
  }

  onDeleteNotifications(own: boolean): void {
    this.notificationService
      .deleteMultiple(own)
      .pipe(take(1))
      .subscribe(() => {
        this.onRefresh();
        this.snackbarService.success(
          this.translateService.instant('notifications.deleteSuccess'),
        );
      });
  }

  onRefresh(): void {
    this.updateLocalStore(this.dataSource.data.map(n => n.id));
    this.disabledRefresh = true;
    this.notificationService.refresh();
  }

  onDelete(element: MemberNotification): void {
    if (!this.canBeDeleted(element)) {
      return;
    }
    this.dataSource = new MatTableDataSource(
      this.dataSource.data.filter(d => d.id !== element.id),
    );
    this.notificationService.delete(element);
  }

  onClose(): void {}

  getColumnsToDisplay(): string[] {
    if (!this.dataSource.data.length) {
      return [];
    } else {
      return this.columnsToDisplay;
    }
  }

  notificationClick(event: MouseEvent): void {
    if (!(event.target as Element).className.includes('class-to-check-close')) {
      event.stopPropagation();
    }
  }

  getNotificationTextColor(element: MemberNotification): string {
    return 'status-' + element.notificationType;
  }

  canBeDeleted(element: MemberNotification): boolean {
    return (
      element.notificationType !== 'running' &&
      this.currentMember?.id === element.member.id
    );
  }

  private notify(notifications: MemberNotification[]): void {
    this.dataSource = new MatTableDataSource(notifications);
    this.setTimer();
    const currentNotificationIds = notifications.map(n => n.id);
    if (
      !stringArrayEqual(this.currentNotificationIds, currentNotificationIds)
    ) {
      this.notificationCenter.notify(true);
      this.updateLocalStore(currentNotificationIds);
    } else {
      if (this.isRunningOrFailedNotification(notifications)) {
        this.notificationCenter.notify(true);
      } else {
        this.notificationCenter.notify(false);
      }
    }
  }

  private setTimer(): void {
    this.disabledRefresh = false;
    this.timeRemaining$ = timer(0, 1000).pipe(
      map(n => 60 - n),
      takeWhile(n => n >= 0),
    );
  }

  private isRunningOrFailedNotification(
    notifications: MemberNotification[],
  ): boolean {
    return !!notifications.find(
      n => n.notificationType === 'running' || n.notificationType === 'failed',
    );
  }

  private updateLocalStore(notifications: string[]): void {
    this.currentNotificationIds = notifications;
    this.storeService.setItem(
      'NotificationCenter-NoOfNotifications',
      notifications,
    );
  }
}
