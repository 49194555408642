<ng-http-loader [filteredUrlPatterns]="dontUseLoaderOnRoutes"
                [entryComponent]="loader"></ng-http-loader>
<ng-template [ngIf]="isLoggedIn" [ngIfElse]="notLoggedIn">
  <app-header [ngClass]="{ 'hide': !showHeader }" [currentMember]="member" [isDefaultBrandColor]="isDefaultBrandColor()" [logo]="logo" [showGamificationBadge]="showGamificationBadge"></app-header>
  <main class="main-layout overflow-y-auto" [class.full-height]="!showHeader" ngClass.lt-md="mobile">
    <div fxLayout="column" class="content-container" ngClass.gt-md="desktop-minimum-width">
      <div class="height-auto" [class.mobile]="mobileQuery.matches" fxFlex="grow">
        <mat-drawer-container [ngClass]="{ 'reporting-route': section === SECTIONS.REPORTING }">
          <mat-drawer 
            *ngIf="mobileQuery.matches || (showBars && !hideSidebar)" 
            #snav
            [mode]="mobileQuery.matches ? 'over' : 'side'"
            [opened]="!mobileQuery.matches && showBars" ngClass.lt-md="mobile"
            [class.expanded]="isSidebarExpanded"
          >
            <app-sidebar *ngIf="member && !mobileQuery.matches && !hideSidebar"
              [member]="member"
              [section]="section"
              [companySettings]="companySettings"
              [isSidebarExpanded]="isSidebarExpanded"
              (sidebarClick)="onSidebarClick($event)">
            </app-sidebar>
            <app-navigation-sidebar 
              *ngIf="member && mobileQuery.matches"
              [isAdmin]="isAdmin" 
              [showGamificationBadge]="showGamificationBadge"
              >
            </app-navigation-sidebar>
          </mat-drawer>
          <mat-drawer-content [class.expanded]="isSidebarExpanded" [class.has-sidebar]="!mobileQuery.matches && showBars && !hideSidebar">
            <app-toolbar [section]="section" [breadcrumbs]="breadcrumbs" [disableActions]="disableActions" [hideActions]="hideActions"
              [enableLoginUsername]="!!companySettings?.enableLoginUsername" [hideMenuButton]="hideMenuButton" [customNewButtonRouterLinks]="customNewButtonRouterLinks"
              (actionOne)="actionOne()" (actionTwo)="actionTwo()" (actionThree)="actionThree()" (actionFour)="actionFour()"
              (actionFilter)="doFilters($event)" (resetFilters)="resetFilters()">
            </app-toolbar>
            <div class="router-outler-wrapper-relative">
              <div class="router-outlet-wrapper">
                <router-outlet></router-outlet>
              </div>
            </div>
          </mat-drawer-content>
        </mat-drawer-container>
      </div>
    </div>
  </main>
</ng-template>

<ng-template #notLoggedIn>
  <router-outlet></router-outlet>
</ng-template>

<app-notification-modal-dialog></app-notification-modal-dialog>
